// Write your custom style. Feel free to split your code to several files
@keyframes wobble {
  0%   { transform: rotate(-1deg) translate(-5px, -5px); }
  50%  { transform: rotate(1deg) translate(5px, 5px); }
  100% { transform: rotate(-1deg) translate(-5px, -5px); }
}
body {
  //color: blue;
}
.btn {
	white-space: nowrap;
}
.theme-blocks {

  img {
    border-radius: 0.25rem;

    &.aos-animate {
      opacity: 0.5 !important;
    }

    &:hover {
      opacity: 1 !important;
      transition: 0.7s !important;
    }
  }

  a {
    position: absolute;
  }

  a:nth-child(1) { animation: wobble 4s infinite; right: 15px; bottom: -20px; }
  a:nth-child(2) { animation: wobble 8s infinite; right: 320px; bottom: 30px; }
  a:nth-child(3) { animation: wobble 5s infinite; right: 0px; bottom: 190px; }
  a:nth-child(4) { animation: wobble 6s infinite; right: 280px; bottom: 180px; }
  a:nth-child(5) { animation: wobble 9s infinite; right: 270px; bottom: 320px; }
  a:nth-child(6) { animation: wobble 7s infinite; right: 20px; bottom: 370px; }
  a:nth-child(7) { animation: wobble 5s infinite; right: 200px; bottom: 470px; }
  a:nth-child(8) { animation: wobble 8s infinite; right: 10px; bottom: 470px; }
  a:nth-child(9) { animation: wobble 9s infinite; right: 0px; bottom: 570px; }

}
.mountain-view {
	background-image: linear-gradient(-45deg, #667eea 0%, #764ba2 100%);
}

.section {
	padding-top: 3rem;
    padding-bottom: 3rem;
}